<template>
  <div class="row q-pt-xl justify-center bg-grey-2" style="height: 100vh;">
    <div>
      <q-card flat bordered style="width: 300px; ">
        <q-card-section class="text-center">
          <q-img
            src="./img/Logo Yayasan Vertikal.png"
            contain
            :ratio="1"
          ></q-img>
          <a class="text-h6 text-indigo-9">Bank Data</a>
          <br />
          <a class="text-caption text-grey-6">Masukkan username dan password</a>
        </q-card-section>
        <q-card-section class="q-gutter-sm q-mb-sm">
          <q-input
            outlined
            v-model="user.username"
            @keyup.enter="$refs.input_password.focus()"
            ref="input_username"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            outlined
            type="password"
            v-model="user.password"
            @keyup.enter="login"
            ref="input_password"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
        </q-card-section>
        <q-btn
          color="indigo-9"
          class="full-width no-border-radius"
          @click="login"
          >login</q-btn
        >
      </q-card>
    </div>
  </div>
</template>

<script>
import General from "@/mixins/General";
import { mapMutations, mapActions } from "vuex";
export default {
  mixins: [General],
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    this.$refs.input_username.focus();
  },
  methods: {
    ...mapMutations(["setRole"]),
    ...mapActions(["sendCurrentUser"]),
    async roleGenerator() {
      let resp = await this.$http.get("/user_data", this.MGetHeader());
      let data = {
        is_admin: false,
        is_yayasan: false,
        is_akademik: false,
        is_pengasuhan: false,
      };
      if (resp.data.role == "administrator") {
        data.is_admin = true;
      } else if (resp.data.role == "pengasuhan") {
        data.is_pengasuhan = true;
      } else if (resp.data.role == "akademik") {
        data.is_akademik = true;
      } else if (resp.data.role == "yayasan") {
        data.is_yayasan = true;
      }
      this.setRole(data);
      await this.sendCurrentUser();
      this.$ROLE_JENJANG = resp.data.jenjang;
      await this.$SET_JENJANG(this.$ROLE_JENJANG);
      this.$router.push("/");
    },
    async login() {
      let user = this.prepareUser();
      try {
        let resp = await this.$http.post("/login", user);
        this.loginSuccess(resp.data);
      } catch (err) {
        this.MNotifyNegative("proses login gagal");
      }
    },
    prepareUser() {
      var md5 = require("md5");
      let user = this.MCopyObject(this.user);
      user.password = md5(user.password);

      return user;
    },
    loginSuccess(token) {
      localStorage.setItem("token", token);
      this.MNotifyPositive("proses login sukses");
      this.$emit("hasLogin");
      this.roleGenerator();
    },
  },
};
</script>
